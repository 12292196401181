
<script>
import Vue from "vue";
import vueCustomElement from "vue-custom-element";
import tester from "./components/tester";


Vue.config.devtools = true;

//custom element factory registration
Vue.use(vueCustomElement);

Vue.customElement("tester", tester);

export default {};
</script>